import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { TwitterShareButton } from 'react-share'
import urljoin from 'url-join'
import Sticky from 'react-sticky-el'

import Nav from '../../components/nav'
import SEO from '../../components/seo'
import Box from '../../components/box'
import Article from '../../components/article'
import Typography from '../../components/typography'
import SocialLinks from '../../components/socialLinks'
import Disqus from '../../components/disqus'
import Footer from '../../components/footer'
import { Banner, InnerBanner, FormContainer } from './listing'

import siteConfig from '../../../data/SiteConfig'
import TwitterSvg from '../../assets/svg/twitter-share-blog.svg'
import '../code-block.css'
import '../integrations.css'
import { useRemoveHotjarScript } from '../../utils/hooks/useHotjarScript'
import Button from '../../components/button'
import OperatorSubscriptionBar from '../../components/operatorSubscriptionBar'

const BlogPostTemplate = props => {
  const { data, pageContext } = props
  const { slug, postType = 'blog' } = pageContext
  const postNode = data.markdownRemark
  const post = postNode.frontmatter
  const {
    author: {
      frontmatter: { firstName, cover: avatar, twitterHandle },
    },
    cover,
    metaDescription,
  } = post

  if (!post.id) {
    post.id = slug
  }

  const ogImage = cover && cover.childImageSharp ? `${cover.childImageSharp.fluid.src}` : undefined

  const [signupSuccess, setSignupSuccess] = useState(false)
  const handleSubmit = event => {
    event.preventDefault()
    const el = event.target
    var formData = new FormData(el)
    fetch(
      'https://www.mailblast.io/lists/5e950c49-d812-4520-a494-f682e1acfb3e/subscribers/create_external',
      {
        method: 'POST',
        body: formData,
      }
    )
      .then(res => {
        if (res.status === 200) {
          setSignupSuccess(true)
        }
        return res.json()
      })
      .then(function (data) {
        if (data && data.error && data.error.length > 0) {
          alert(data.error[0])
        }
      })
  }

  useRemoveHotjarScript()

  return (
    <>
      <SEO
        title={`${post.title}`}
        description={metaDescription}
        ogImage={ogImage}
        twImage={ogImage}
      />
      <Nav theme="light" style={{ background: '#FFFFFF', position: 'absolute' }} />
      <OperatorSubscriptionBar
        backName={postType === 'blog' ? 'Blog' : postType === 'changelog' ? 'Changelog' : 'Cult Operations'}
        backPath={postType === 'blog' ? '/blog' : postType === 'changelog' ? '/changelog' : '/operators'}
      />
      <ArticleLayout>
        <Sticky>
          <SocialsContainer>
            <SocialLinks
              postPath={urljoin(siteConfig.siteUrl, 'blog', slug)}
              postNode={postNode}
              key="social-large"
            />
          </SocialsContainer>
        </Sticky>

        <ArticleContainer>
          <div className='font-semibold text-4xl leading-[42px] font-display mb-24'>
            {post.title}
          </div>
          {/* <Box
            display="flex"
            alignItems="center"
            marginTop="12px"
            height="auto"
            marginBottom="24px"
          >
            <Typography component="span">
              <span id="date">{post.date}</span>
            </Typography>
          </Box> */}
          {cover && (
            <Box marginBottom="24px">
              <Img
                fluid={cover.childImageSharp && cover.childImageSharp.fluid}
                alt={post.title}
                style={{ width: '100%', borderRadius: '6px' }}
              />
            </Box>
          )}
          <BlogArticle dangerouslySetInnerHTML={{ __html: postNode.html }} />

          <Sticky mode="bottom">
            <MobileShare>
              <SocialLinks
                postPath={urljoin(siteConfig.siteUrl, 'blog', slug)}
                postNode={postNode}
                horizontal
                size={24}
                key="mobile-links"
              />
            </MobileShare>
          </Sticky>

          <Box marginBottom="32px" />
          <Disqus postNode={postNode} />
        </ArticleContainer>
        {postType !== 'changelog' && (
          <Sticky>
            <AuthorContainer>
              <AuthorCard key="author-large">
                <span className="written-by">
                  <Box
                    width="18px"
                    height="18px"
                    marginRight="8px"
                    borderRadius="100%"
                  >
                    <Img
                      fluid={avatar.childImageSharp.fluid}
                      alt={firstName}
                      style={{ borderRadius: '100px' }}
                    />
                  </Box>
                  <span>Written by </span> &nbsp;
                  <a
                    href={`https://twitter.com/${twitterHandle.replace('@', '')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {twitterHandle}
                  </a>
                </span>
                <TwitterShareButton
                  url={urljoin(siteConfig.siteUrl, 'blog', slug)}
                  title={post.title}
                  className="social-btn"
                >
                  <span className="share-twitter">
                    <TwitterSvg />
                    Share on twitter
                  </span>
                </TwitterShareButton>
              </AuthorCard>
            </AuthorContainer>
          </Sticky>
        )}
      </ArticleLayout>

      <Footer />
    </>
  )
}

export default BlogPostTemplate

const StyledLink = styled(Link)`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  align-self: flex-start;

  @media ${props => props.theme.device.tablet} {
    align-self: center;
  }

  svg {
    margin-right: 4px;
    transition: 100ms ease;
    transform: translateX(0);
  }

  :hover,
  :focus {
    svg {
      transform: translateX(-2px);
    }
  }
`

const StyledInput = styled.input`
  background: #444444;
  /* Shadows/Default */

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  font-family: Suisse Intl;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 16px;
`

const ArticleLayout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  box-sizing: border-box;
  position: relative;
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};

  @media (min-width: 1160px) {
    padding-left: ${props => props.theme.spacing(10)};
    padding-right: ${props => props.theme.spacing(10)};
  }

  @media (min-width: 1200px) {
    padding-left: calc(50vw + 40px - 600px);
    padding-right: calc(50vw + 40px - 600px);
  }
`

const SocialsContainer = styled.div`
  display: none;

  @media ${props => props.theme.device.tablet} {
    margin-top: 64px;
    display: flex;
    position: absolute;
  }
`

const AuthorContainer = styled.div`
  display: none;
  left: -195px;
  @media ${props => props.theme.device.laptop} {
    margin-top: 64px;
    display: flex;
    position: absolute;
  }
`

const AuthorCard = styled.div`
  padding: 16px 20px;
  width: 233px;
  height: 82px;
  background: #1f1f1f;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .written-by {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #cbcbcb;
    margin-bottom: 8px;

    a {
      display: inline;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.37), rgba(255, 255, 255, 0.37)),
      linear-gradient(90deg, #9D63F0 -2.15%, #0FDCEF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .share-twitter {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.37), rgba(255, 255, 255, 0.37)),
    linear-gradient(90deg, #9D63F0 -2.15%, #0FDCEF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    flex: none;
    display: flex;
    align-items: center;

    svg {
      margin-right: 6px;
    }
  }
`

const ArticleContainer = styled.main`
  width: 700px;
  max-width: 700px;
  margin: auto;
  padding-bottom: 40px;
  padding-top: 12px;
  margin-top: 40px;
  box-sizing: border-box;

  @media ${props => props.theme.device.tablet} {
    padding-left: 30px;
  }

  #date {
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #757575;
  }
`

const BlogArticle = styled(Article)`
  blockquote {
    background-color: rgb(238 249 226);
  }

  a {
    color: #b0ed6a !important;
  }
  
  ul {
    list-style: disc;
    margin-left: 12px;
    padding-left: 12px;
    margin-bottom: 12px;
  }
  
  ol {
    list-style: auto;
    margin-left: 12px;
    padding-left: 12px;
    margin-bottom: 12px;
  }
`

const MobileShare = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 16px;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -16px;

  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`

export const blogPageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        metaDescription
        cover {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date(formatString: "MMMM Do, YYYY")
        category
        tags
        author {
          frontmatter {
            firstName
            lastName
            cover {
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            slug
            email
            twitterHandle
            bio
          }
        }
      }
      fields {
        slug
        date
      }
    }
  }
`
