import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
  RedditShareButton,
} from 'react-share'
import urljoin from 'url-join'
import config from '../../data/SiteConfig'

import EmailIcon from '../assets/svg/social-email.svg'
import FacebookIcon from '../assets/svg/social-facebook.svg'
import LinkedinIcon from '../assets/svg/social-linkedin.svg'
import RedditIcon from '../assets/svg/social-reddit.svg'
import TwitterIcon from '../assets/svg/social-twitter.svg'

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1.875rem;

  ${props =>
    props.horizontal &&
    css`
      flex-direction: row;
      width: auto;

      * {
        margin-right: 0.75rem;
      }
    `}

  button {
    display: flex;
    align-items: center;
  }

  * {
    margin-bottom: 0.75rem;
  }
`

class SocialLinks extends Component {
  render() {
    const { postNode, postPath, horizontal = false, size = 24 } = this.props
    const post = postNode.frontmatter
    const url = postPath

    const emailSubject = `Have a look at this!`
    const emailBody = `Hi,\nI think you will find interesting this article about "${post.title}".\n\n Link: \n`

    return (
      <SocialsContainer horizontal={horizontal}>
        <TwitterShareButton url={url} title={post.title} className="social-btn">
          <TwitterIcon style={{ width: size + 'px', height: size + 'px' }} />
        </TwitterShareButton>
        <LinkedinShareButton
          url={url}
          title={post.title}
          description={postNode.excerpt}
          className="social-btn"
        >
          <LinkedinIcon style={{ width: size + 'px', height: size + 'px' }} />{' '}
        </LinkedinShareButton>
        <FacebookShareButton
          url={url}
          quote={postNode.excerpt}
          className="social-btn"
        >
          <FacebookIcon style={{ width: size + 'px', height: size + 'px' }} />
        </FacebookShareButton>

        <EmailShareButton
          url={url}
          subject={emailSubject}
          body={emailBody}
          className="social-btn"
        >
          <EmailIcon style={{ width: size + 'px', height: size + 'px' }} />{' '}
        </EmailShareButton>

        {/* <RedditShareButton title={post.title}>
          <RedditIcon style={{ width: size + 'px', height: size + 'px' }} />
        </RedditShareButton> */}
      </SocialsContainer>
    )
  }
}

export default SocialLinks
