import styled from 'styled-components'

const Article = styled.article`
  max-width: 100%;
  box-sizing: border-box;

  h1 {
    font-family: ${props => props.theme.typography.fontFamilySuisseIntl};
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    margin: 32px 0;
  }

  h2 {
    font-family: ${props => props.theme.typography.fontFamilySuisseIntl};
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    color: #000000;
    margin: 32px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${props => props.theme.typography.fontFamilySuisseIntl};
    font-style: normal;
    font-weight: bold;
  }

  a {
    color: #42ade7 !important;
    font-weight: 600;
    text-decoration: none;
  }

  img {
    width: 90%;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 16px;
    box-sizing: border-box;
  }

  span,
  p {
    font-family: ${props => props.theme.typography.fontFamilyInter};
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    color: ${props => props.theme.core.content.secondary};
    margin: 24px 0;
  }
  ul,
  li {
    font-family: ${props => props.theme.typography.fontFamilyInter};
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    color: ${props => props.theme.core.content.secondary};
    margin: 0;
  }

  blockquote {
    background: #fffbe8;
    padding: 19.2px;
    margin: 0;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  blockquote p:last-of-type {
    margin: 0;
  }
  blockquote li {
    margin-bottom: 3.2px;
  }
`

export default Article
